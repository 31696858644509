import React from "react";
import { Link } from "gatsby";

import Page from "../components/Page";
import SEO from "../components/SEO";

import { others } from "../helpers/navigation";

const ThankYouPage = () => {
  return (
    <Page
      page="ThankYou"
      pageName="Дякуємо!"
      title="Дякуємо!"
      parameters={others}
    >
      <SEO title="Дякуємо!" />
      <div className="ThankYou_block">
        <p>
          Ваше повідомлення успішно надіслано.
          <br />
          Ми будемо намагатись якнайшвидше дати вам відповідь.
        </p>
        <Link to="/" className="ThankYou_block__link">
          повернутись на сайт
        </Link>
      </div>
    </Page>
  );
};

export default ThankYouPage;
